<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h3>Mijn bestellingen</h3>

        <DataTable :value="orders" v-model:expandedRows="expandedRows" dataKey="id">
          <Column :expander="true" headerStyle="width: 3rem" />

          <Column field="createdAt" header="Datum" :sortable="true"></Column>
          <Column field="completed" header="Status" :sortable="true">
            <template #body="{data}">
              <span :class="`customer-badge status-${data.completed ? 'completed' : 'in-progress'}`">{{data.completed ? 'Ontvangen' : 'In behandeling'}}</span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div>
              <h5>Producten</h5>
              <DataTable :value="slotProps.data.items" responsiveLayout="scroll">
                <Column field="name" header="Product" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">Product</span>
                    {{data.name}}
                  </template>
                </Column>
                <Column field="quantity" header="Aantal" :sortable="true">
                  <template #body="{data}">
                    <span class="p-column-title">Aantal</span>
                    {{data.quantity}}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
          <template #empty>
            Geen bestellingen gevonden
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Translate from '../mixins/Translate.js'

export default {
  mixins: [Translate],
  data() {
    return {
      orders: null,
      products: null,
      expandedRows: [],
    }
  },
  mounted() {
    this.$root.orderService.getOrders().then(data => this.orders = data);
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    margin-right: 10px;
  }
}
.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-completed {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-in-progress {
    background: #FFD8B2;
    color: #805B36;
  }
}
</style>
